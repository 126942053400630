import { Link } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import BoxContainer from "./BoxContainer"
import * as styles from "./Trust.module.scss"

const TrustExpert = ({ strapiData }) => {
  return (
    <section className={styles.trustMain}>
      <Container>
        <div
          className="text-center"
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        ></div>
        <div
          className={`${styles.subHeading} text-center pb-4 pt-2`}
          dangerouslySetInnerHTML={{
            __html: strapiData?.description?.description,
          }}
        />

        <Row className="px-xl-5">
          {strapiData?.cards &&
            strapiData?.cards?.map((item, index) => (
              <BoxContainer
                Image={item?.image1[0]?.localFile?.publicURL}
                Alt={item?.image1[0]?.alternativeText}
                Heading={item?.title}
                Desc={item?.description?.description}
                key={index}
              />
            ))}
        </Row>
        <div className="pt-5 text-center">
          {strapiData?.buttons[0] && (
            <Link to={strapiData?.buttons[0]?.url} className="btn_black_border">
              {strapiData?.buttons[0]?.title}
            </Link>
          )}
        </div>
      </Container>
    </section>
  )
}

export default TrustExpert
