import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Dots from "../../images/product-development/dotsquare.svg"
import TriangleBottom from "../../images/product-development/triangle-bottom.svg"
import ARROW from "../../images/product-development/ARROW.png"
import AccordianHover from "./AccordianHover"
import * as styles from "./WellGrounded.module.scss"

const WellGrounded = ({ strapiData }) => {
  return (
    <section className={styles.wellGroundedMain}>
      <Container>
        <h2
          className="text-center"
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />

        <div
          className={`${styles.subHeading} text-center pb-4 pt-2`}
          dangerouslySetInnerHTML={{
            __html: strapiData?.description?.description,
          }}
        />
        <Row className="justify-content-center">
          <Col lg={6} md={12}>
            {strapiData?.cards &&
              strapiData?.cards?.map((el, id) => (
                <AccordianHover
                  key={id}
                  image={el?.image1[0]?.localFile?.publicURL}
                  Heading={el?.title}
                  AccordianBody={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: el?.description?.description,
                      }}
                    ></div>
                  }
                />
              ))}
          </Col>

          <Col
            lg={6}
            md={12}
            className="text-center text-lg-right mt-5 mt-md-0"
          >
            <img
              src={strapiData?.secImages[0]?.localFile?.publicURL}
              alt={strapiData?.secImages[0]?.alternativeText}
              width="100%"
              decoding="async"
              loading="lazy"
              height="auto"
            />
            <img
              className={styles.bannerTopIcon}
              src={Dots}
              alt="bottom triangle"
              decoding="async"
              loading="lazy"
            />
          </Col>
        </Row>
        <img
          className={styles.bannerBottomImg}
          src={TriangleBottom}
          alt="bottom triangle"
          decoding="async"
          loading="lazy"
        />
      </Container>
      <img
        className={styles.bannerLeftIcon}
        src={Dots}
        alt="dots"
        decoding="async"
        loading="lazy"
      />
      <img
        className={styles.ArrowIcon}
        src={ARROW}
        alt="dots"
        decoding="async"
        loading="lazy"
      />
    </section>
  )
}

export default WellGrounded
