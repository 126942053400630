import { graphql } from "gatsby"
import React from "react"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import Banner from "../components/product-development/Banner"
import ExploreIngredients from "../components/product-development/ExploreIngredients"
import RockSolidSoftware from "../components/product-development/RockSolidSoftware"
import TrustExpert from "../components/product-development/TrustExpert"
import WellGrounded from "../components/product-development/WellGrounded"
import WhyInvoZone from "../components/product-development/WhyInvoZone"
import SliderNode from "../components/product-development/sliderNode"
import AngularTechStacks from "../components/application-development/AngularTechStacks"
import AngularTechStacksMobile from "../components/application-development/AngularTechStacksMobile"
import MainLayout from "../layouts/MainLayout"

const ProductDevelopment = ({ data }) => {
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 768)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const banner = data?.strapiPage?.sections[0]
  const trust = data?.strapiPage?.sections[1]
  const wellgournd = data?.strapiPage?.sections[2]
  const rockSolid = data?.strapiPage?.sections[3]
  const explore = data?.strapiPage?.sections[4]
  const slider = data?.strapiPage?.sections[5]
  const why = data?.strapiPage?.sections[6]
  const faqs = data?.strapiPage?.sections[7]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout bgChanged={false} schemas={bodySchema}>
      <Banner strapiData={banner} breadCrumb={data?.strapiPage?.buttons} />
      <TrustExpert strapiData={trust} />
      <WellGrounded strapiData={wellgournd} />
      <RockSolidSoftware strapiData={rockSolid} />
      <ExploreIngredients strapiData={explore} />
      {!isMobile && !isTablet ? (
        <AngularTechStacks heading={"Our Tech Expertise"} />
      ) : (
        <AngularTechStacksMobile heading={"Our Tech Expertise"} />
      )}
      <SliderNode strapiData={slider} />
      <WhyInvoZone strapiData={why} />
      <Faqs strapiData={faqs} />
    </MainLayout>
  )
}

export const query = graphql`
  query productDevlopment {
    strapiPage(slug: { eq: "product1" }) {
      sections {
        title
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`
export default ProductDevelopment

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      schemas={headSchema}
      image="https://invozone-backend.s3.amazonaws.com/software_product_development_services_b1fd581822.svg"
    />
  )
}
