import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import One from "../../images/product-development/01.svg"
import Two from "../../images/product-development/02.svg"
import Three from "../../images/product-development/03.svg"
import Four from "../../images/product-development/04.svg"
import Five from "../../images/product-development/05.svg"
import Six from "../../images/product-development/06.svg"
import Dots from "../../images/product-development/dotsquare.svg"
import ARROW from "../../images/product-development/ARROW.png"
import Vector from "../../images/product-development/Vector.png"
import Vector1 from "../../images/product-development/Vector1.png"
import cir from "../../images/product-development/cir.png"
import Frame from "../../images/product-development/Frame.png"
import * as styles from "./ExploreIngredients.module.scss"

const IngredientData = [
  {
    img: One,
  },
  {
    img: Two,
  },
  {
    img: Three,
  },
  {
    img: Four,
  },
  {
    img: Five,
  },
  {
    img: Six,
  },
]

const ExploreIngredients = ({ strapiData }) => {
  return (
    <section className={styles.exploreMain}>
      <img
        className={styles.CirIcon}
        src={cir}
        alt="dots"
        decoding="async"
        loading="lazy"
      />
      <img
        className={styles.VectorIcon}
        src={Vector}
        alt="dots"
        decoding="async"
        loading="lazy"
      />
      <img
        className={styles.VectorIcon1}
        src={Vector1}
        alt="dots"
        decoding="async"
        loading="lazy"
      />
      <img
        className={styles.bannerTopIcon}
        src={Dots}
        alt="dots"
        decoding="async"
        loading="lazy"
      />
      <img
        className={styles.bannerRightIcon}
        src={Dots}
        alt="dots"
        decoding="async"
        loading="lazy"
      />
      <img
        className={styles.bannerBottomIcon}
        src={Dots}
        alt="dots"
        decoding="async"
        loading="lazy"
      />
      <Container>
        <h2
          className="text-center"
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={`${styles.subHeading} text-center pb-4 pt-2`}
          dangerouslySetInnerHTML={{
            __html: strapiData?.description?.description,
          }}
        />

        <Row className={`${styles.rowContainer} justify-content-center `}>
          {strapiData?.cards?.map((item, index) => (
            <Col xl={4} lg={6} md={12} key={index}>
              <div
                className={`${styles.bottomCard} ${
                  index === 1 ? styles.index3 : index === 4 ? styles.index3 : ""
                }`}
              >
                <img
                  decoding="async"
                  loading="lazy"
                  src={IngredientData[index].img}
                  alt={item?.title}
                />
                <div className={`${styles.title} pb-2`}>
                  <h3
                    className=" pr-2 pb-2"
                    dangerouslySetInnerHTML={{ __html: item?.title }}
                  />
                </div>
                <div
                  className={`${styles.desc} mt-4`}
                  dangerouslySetInnerHTML={{
                    __html: item?.description?.description,
                  }}
                />
              </div>
            </Col>
          ))}
        </Row>
        <div className="text-center">
          {strapiData?.buttons[0] && (
            <Link to={strapiData?.buttons[0]?.url} className="btn_black_border">
              {strapiData?.buttons[0]?.title}
            </Link>
          )}
        </div>
      </Container>
      <img
        className={styles.ArrowIcon}
        src={ARROW}
        alt="dots"
        decoding="async"
        loading="lazy"
      />
      <img
        className={styles.FrameIcon}
        src={Frame}
        alt="dots"
        decoding="async"
        loading="lazy"
      />
    </section>
  )
}

export default ExploreIngredients
