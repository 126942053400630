import React, { useState } from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import * as styles from "./Trust.module.scss"

const BoxContainer = props => {
  const [showMore, setShowMore] = useState(false)
  const [mobile, isMobile] = useState(false)

  const mobileQuery = () => {
    if (window.innerWidth < 768) {
      isMobile(true)
    } else {
      isMobile(false)
    }
  }

  React.useEffect(() => {
    isMobile(window.innerWidth < 768)
    window.addEventListener("resize", mobileQuery)
    return () => {
      window.removeEventListener("resize", mobileQuery)
    }
  }, [])
  return (
    <Col lg={6} className="my-3  ">
      <div
        className={styles.boxContainer}
        onMouseLeave={() => setShowMore(false)}
      >
        <div className="d-flex flex-column flex-md-row">
          <div>
            <div
              className={`${styles.imgWrapper} ${showMore && styles.invertImg}`}
            >
              <img
                decoding="async"
                loading="lazy"
                src={props?.Image}
                alt={props?.Alt}
              />
            </div>
          </div>
          <div className={`${styles.content} pl-3`}>
            <h3 className={styles.boxHeading}>{props?.Heading}</h3>
            {mobile ? (
              <div
                className={styles.boxDesc}
                dangerouslySetInnerHTML={{
                  __html: props?.Desc,
                }}
              ></div>
            ) : (
              <div
                className={styles.boxDesc}
                dangerouslySetInnerHTML={{
                  __html: showMore
                    ? props?.Desc
                    : props?.Desc?.substring(0, 75) + "...",
                }}
              ></div>
            )}
            {mobile ? (
              " "
            ) : (
              <div className="pt-3">
                {!showMore && (
                  <Button
                    className="btn_black_border"
                    style={{ maxWidth: "200px" }}
                    onClick={() => setShowMore(!showMore)}
                  >
                    Learn More
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Col>
  )
}

export default BoxContainer
