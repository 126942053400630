import React from "react"
import Container from "react-bootstrap/Container"
import leftArr from "../../images/svg/leftArr.svg"
import rightArr from "../../images/svg/rightArr.svg"
import "swiper/css"
import { Swiper, SwiperSlide, useSwiper } from "swiper/react"
import * as styles from "./sliderNode.module.scss"
import "../common/nodejs/slider.scss"

const SwiperButtonNext = () => {
  const swiper = useSwiper()
  return (
    <div
      className={styles.portfolioArrowIconCover}
      onClick={() => swiper.slideNext()}
    >
      <img
        src={rightArr}
        decoding="async"
        loading="lazy"
        onClick={() => swiper.slideNext()}
        className={styles.portfolioArrowIcon}
        alt="right arrow"
      />
    </div>
  )
}
const SwiperButtonPrev = () => {
  const swiper = useSwiper()
  return (
    <div
      className={styles.portfolioArrowRightIconCover}
      onClick={() => swiper.slidePrev()}
    >
      <img
        src={leftArr}
        decoding="async"
        loading="lazy"
        onClick={() => swiper.slidePrev()}
        className={styles.portfolioArrowIcon}
        alt="left arrow"
      />
    </div>
  )
}
const SliderNode = ({ strapiData }) => {
  return (
    <div className={styles.sliderHead}>
      <Container>
        <div
          className="text-center"
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />

        <div
          className={`text-center pb-4 pt-2 pl-2`}
          dangerouslySetInnerHTML={{
            __html: strapiData?.description?.description,
          }}
        />

        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          loop={true}
          initialSlide={1}
          className="pt-4"
          centeredSlides={true}
          breakpoints={{
            768: {
              slidesPerView: 2,
              initialSlide: 0,
            },
            992: {
              slidesPerView: 3,
              initialSlide: 3,
              centerSlides: false,
            },
            1280: {
              slidesPerView: 3,
              initialSlide: 3,
              centerSlides: false,
            },
            1366: {
              slidesPerView: 4,
              initialSlide: 4,
            },
          }}
        >
          <div className={styles.mainContainer}>
            <div className={styles.arrowContainer}>
              <SwiperButtonPrev />
              <SwiperButtonNext />
            </div>
          </div>
          {strapiData?.cards &&
            strapiData?.cards?.map((e, i) => (
              <SwiperSlide className="idSwiper" key={i}>
                {({ isActive }) => (
                  <div
                    className={`${styles.card} ${
                      isActive ? "activeSlider" : ""
                    }`}
                  >
                    <div className={styles.imageContainer}>
                      <img
                        decoding="async"
                        loading="lazy"
                        src={
                          isActive
                            ? e?.image2[1]?.localFile.publicURL
                            : e?.image2[0]?.localFile.publicURL
                        }
                        alt={
                          isActive
                            ? e?.image2[1]?.alternativeText
                            : e?.image2[0]?.alternativeText
                        }
                        className={`${
                          isActive ? styles.imageActive : styles.imageNotActive
                        }`}
                      />
                      <img
                        decoding="async"
                        loading="lazy"
                        src={e?.image1[0]?.localFile?.publicURL}
                        alt={e?.image1[0]?.alternativeText}
                        className={styles.mainImage}
                      />
                    </div>
                    {isActive && (
                      <div
                        className="cardBody"
                        dangerouslySetInnerHTML={{
                          __html: e?.description?.description,
                        }}
                      />
                    )}
                  </div>
                )}
              </SwiperSlide>
            ))}
        </Swiper>
      </Container>
    </div>
  )
}

export default SliderNode
