// extracted by mini-css-extract-plugin
export var ArrowIcon = "ExploreIngredients-module--ArrowIcon--5d2a2";
export var CirIcon = "ExploreIngredients-module--CirIcon--52ad4";
export var FrameIcon = "ExploreIngredients-module--FrameIcon--0bcc7";
export var VectorIcon = "ExploreIngredients-module--VectorIcon--71fb2";
export var VectorIcon1 = "ExploreIngredients-module--VectorIcon1--66bd4";
export var bannerBottomIcon = "ExploreIngredients-module--bannerBottomIcon--5fe9b";
export var bannerRightIcon = "ExploreIngredients-module--bannerRightIcon--7b93c";
export var bannerTopIcon = "ExploreIngredients-module--bannerTopIcon--30c3d";
export var bottomCard = "ExploreIngredients-module--bottomCard--89ddc";
export var desc = "ExploreIngredients-module--desc--bbaed";
export var exploreMain = "ExploreIngredients-module--exploreMain--59004";
export var index2 = "ExploreIngredients-module--index2--6e30c";
export var index3 = "ExploreIngredients-module--index3--e4102";
export var rowContainer = "ExploreIngredients-module--rowContainer--b219e";
export var subHeading = "ExploreIngredients-module--subHeading--bc0fb";
export var title = "ExploreIngredients-module--title--09d33";