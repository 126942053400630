// extracted by mini-css-extract-plugin
export var arrowContainer = "sliderNode-module--arrowContainer--e09c0";
export var card = "sliderNode-module--card--460e9";
export var cardBody = "sliderNode-module--cardBody--0a7c2";
export var description = "sliderNode-module--description--f70e0";
export var heading = "sliderNode-module--heading--241ad";
export var imageActive = "sliderNode-module--imageActive--6c89b";
export var imageContainer = "sliderNode-module--imageContainer--2b4d1";
export var imageNotActive = "sliderNode-module--imageNotActive--a8afc";
export var mainContainer = "sliderNode-module--mainContainer--3ec3a";
export var mainImage = "sliderNode-module--mainImage--28092";
export var portfolioArrowIcon = "sliderNode-module--portfolioArrowIcon--f0051";
export var portfolioArrowIconCover = "sliderNode-module--portfolioArrowIconCover--ba652";
export var portfolioArrowRightIconCover = "sliderNode-module--portfolioArrowRightIconCover--a1e96";
export var sliderHead = "sliderNode-module--sliderHead--aa464";