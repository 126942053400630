import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Dots from "../../images/product-development/whyDots.png"
import * as styles from "./WhyInvoZone.module.scss"

const WhyInvoZone = ({ strapiData }) => {
  return (
    <div className={styles.invozoneBanner}>
      <img
        className={styles.bannerTopIcon}
        src={Dots}
        alt="bottom triangle"
        decoding="async"
        loading="lazy"
      />
      <Container className={styles.invozoneContainer}>
        <Row className="align-items-center gap-30">
          <Col lg={6} md={12} className={`${styles.textColumn} pt-5`}>
            <h2
              className={`text-primary`}
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            />

            <div
              className={`${styles.subHeading} pt-5`}
              dangerouslySetInnerHTML={{
                __html: strapiData?.description?.description,
              }}
            ></div>
            <div className="pt-4">
              {strapiData?.buttons[0] && (
                <Link
                  to={strapiData?.buttons[0]?.url}
                  className="btn_black_border"
                >
                  {strapiData?.buttons[0]?.title}
                </Link>
              )}
            </div>
          </Col>
          <Col
            lg={6}
            md={12}
            className="text-center text-lg-right mt-5 mt-md-0"
          >
            <img
              src={strapiData?.secImages[0]?.localFile.publicURL}
              alt={strapiData?.secImages[0]?.alternativeText}
              width="100%"
              decoding="async"
              loading="lazy"
              height="auto"
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default WhyInvoZone
