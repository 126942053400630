import { Link } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import RockSolid from "../../images/product-development/rocksolidbg.svg"
import * as styles from "./RockSolidSoftware.module.scss"

const RockSolidSoftware = ({ strapiData }) => {
  return (
    <section className={styles.rockMain}>
      <Container>
        <h2
          className="text-center"
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        ></h2>
        <div
          className={`${styles.subHeading} text-center pt-4`}
          dangerouslySetInnerHTML={{
            __html: strapiData?.description?.description,
          }}
        />
        <div className={styles.SolidMain}>
          <img
            decoding="async"
            loading="lazy"
            width="100%"
            height="auto"
            src={RockSolid}
            alt="rock-solid"
          />
        </div>
        <div className="text-center">
          {strapiData?.buttons[0] && (
            <Link to={strapiData?.buttons[0]?.url} className="btn_black_border">
              {strapiData?.buttons[0]?.title}
            </Link>
          )}
        </div>
      </Container>
    </section>
  )
}

export default RockSolidSoftware
